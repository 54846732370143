<template>
    <section>
        <nav class="box is-flex is-flex is-justify-content-space-between is-align-items-center">
            <b-field :label="$t('CONCILIACAOBANCARIA.DATATRANSACAO')">
                {{$route.params.dataTransacao | moment('DD/MM/YYYY')}}
            </b-field>
            <b-field :label="$t('CONCILIACAOBANCARIA.VALORESPERADO')">
                {{somaTodasTransacoes / 100 | currency}}
            </b-field>
            <b-field :label="$t('CONCILIACAOBANCARIA.VALORREGISTRADO')">
                {{somaTodosLancamentosFinanceiros / 100 | currency}}
            </b-field>
            <div class="buttons">
                <b-button type="is-info" v-if="somenteLeituraCasoConciliacaoNaoEstaNaOrdemCorreta">
                    {{$t('SISTEMA.SOMENTELEITURA')}}
                </b-button>
                <b-button @click="desconciliar" v-else-if="dadosConciliacao.estaConciliado && isInRole('conciliacaoBancaria-desconciliar')" type="is-danger" :loading="isLoading" icon-left="close-circle">
                    {{$t('CONCILIACAOBANCARIA.DESCONCILIAR')}}
                </b-button>
                <b-button @click="conciliar" v-else-if="!dadosConciliacao.estaConciliado && isInRole('conciliacaoBancaria-conciliar')" :disabled="somaTodosLancamentosFinanceiros != somaTodasTransacoes" type="is-success" :loading="isLoading" icon-left="check-circle">
                    {{$t('CONCILIACAOBANCARIA.CONCILIAR')}}
                </b-button>
            </div>
        </nav>
        <article class="columns" v-for="conciliacao in dadosConciliacao.conciliacoes" :key="conciliacao.id">
            <div class="column">
                <div class="card">
                    <header class="card-header">
                        <p class="card-header-title">
                            {{conciliacao.transacao.valor / 100 | currency}}
                        </p>
                    </header>
                    <div class="card-content">
                        <div class="content">
                            <p v-html="conciliacao.transacao.descricao.replace(/\r\n|\n/g, '<br>')">
    </p>
                        </div>
                    </div>
                </div>
            </div>
            <div class="column">
                <div class="card">
                    <header class="card-header">
                        <p class="card-header-title">
                            {{somaLancamentoFinanceiroPorTransacao(conciliacao.transacao.id) / 100 | currency}}
                        </p>
                        <div 
                            v-show="!dadosConciliacao.estaConciliado && !somenteLeituraCasoConciliacaoNaoEstaNaOrdemCorreta" 
                            @click="buscarLancamentosParaTransacao(conciliacao.transacao.id)" 
                            class="card-header-icon" 
                            aria-label="more options"
                        >
                            <b-icon
                                icon="magnify"
                                size="is-small">
                            </b-icon>
                        </div>
                    </header>
                    <div class="card-content">
                        <div v-if="conciliacao.lancamentosFinanceiros.length" class="content">
                            <listaLancamentosFinanceiros
                                :lancamentos-financeiros="conciliacao.lancamentosFinanceiros"
                                :podeRemoverItem="false"
                            >

                            </listaLancamentosFinanceiros>
                        </div>
                        <div v-else class="content">
                            <p>
                                {{$t('CONCILIACAOBANCARIA.NENHUMLANCAMENTORELACIONADO')}}
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </article>
    </section>
</template>

<style scoped>

</style>
<script>

import listaLancamentosFinanceiros from "@/components/conciliacaoBancaria/listaLancamentosFinanceiros.vue"
import { mapGetters } from 'vuex'

export default {
    data() {
        return {
            isLoading: false,
            dataDiferente: false
        };
    },
    components: {
        listaLancamentosFinanceiros
    },
    props: {
        dadosConciliacao: {
            estaConciliado: false,
            conciliacoes: []
        }
    },
    created() {
        this.buscarUltimaTransacaoPendenteParaConciliar()
    },
    computed: {
        somenteLeituraCasoConciliacaoNaoEstaNaOrdemCorreta()
        {
            return this.dataDiferente == true && this.dadosConciliacao.estaConciliado == false
        },
        somaTodasTransacoes()
        {
            return this.dadosConciliacao.conciliacoes.reduce((valorAnterior, conciliacaoAtual)  => valorAnterior + conciliacaoAtual.transacao.valor, 0)
        },
        somaTodosLancamentosFinanceiros()
        {
            return this.dadosConciliacao.conciliacoes.reduce((valorAnterior, conciliacaoAtual) => {
                return valorAnterior + this.somaLancamentoFinanceiroPorTransacao(conciliacaoAtual.transacao.id)
            }, 0)
        },
        somaLancamentoFinanceiroPorTransacao()
        {
            return (id) => {
                return this.obterConciliacaoPorIdDaTransacao(id)
                .lancamentosFinanceiros
                .reduce((valorAnterior, lancamentoFinanceiro) => {
                    let valorLancamentoFinanceiro = 0
                    if (lancamentoFinanceiro.parcelaReceber != null)
                    {
                        valorLancamentoFinanceiro = lancamentoFinanceiro.parcelaReceber.valor * 100
                    }

                    if (lancamentoFinanceiro.parcelaPagar != null)
                    {
                        valorLancamentoFinanceiro = (-lancamentoFinanceiro.parcelaPagar.valor) * 100
                    }
                    return valorAnterior + valorLancamentoFinanceiro
                }, 0)
            }
        },
        obterConciliacaoPorIdDaTransacao()
        {
            return (id) => this.dadosConciliacao.conciliacoes.find(conciliacao => conciliacao.transacao.id == id);
        },
        ...mapGetters([
            'isInRole'
        ])
    },
    methods: {
        conciliar() 
        {
            this.isLoading = true
            this.$http
            .post(`/api/ConciliacaoBancaria/realizarConciliacao`, this.dadosConciliacao.conciliacoes)
            .then(() => {
                this.isLoading = false
                this.$emit('conciliou')
                this.$router.push(
                    { name: 'extrato-conciliacao', 
                    params: { contaCorrenteId: this.dadosConciliacao.conciliacoes[0].transacao.contaCorrente.id} 
                });
            })
            .catch((error) => {
                this.$buefy.toast.open({
                    duration: 5000,
                    message: error.body.mensagem,
                    type: "is-danger",
                    queue: false,
                })
                this.isLoading = false
                console.error(error)
            })
        },
        desconciliar()
        {
            this.isLoading = true
            const json = {
                DataConciliada: this.$route.params.dataTransacao,
                ContaCorrenteId: this.$route.params.contaCorrenteId
            }

            this.$http
            .post(`/api/ConciliacaoBancaria/desfazerConciliacao`, json)
            .then(() => {
                this.dadosConciliacao.estaConciliado = false;
                this.isLoading = false
                this.$emit('desconciliou')
                this.buscarUltimaTransacaoPendenteParaConciliar()
            })
            .catch((error) => {
                this.$buefy.toast.open({
                    duration: 5000,
                    message: error.body.mensagem,
                    type: "is-danger",
                    queue: false,
                })
                this.isLoading = false
                console.error(error)
            })
        },
        buscarLancamentosParaTransacao(id)
        {
            this.$emit('selecionouTransacao', id)
        },
        buscarUltimaTransacaoPendenteParaConciliar()
        {
            this.$http.get(`/api/ConciliacaoBancaria/BuscarUltimaTransacaoPendente?contaCorrenteId=${this.$route.params.contaCorrenteId}`)
                .then(res => {
                    if (res.data)
                    {
                        this.dataDiferente = this.$moment(res.data.dataTransacao).format('YYYYMMDD') != 
                        this.$moment(this.$route.params.dataTransacao).format('YYYYMMDD')
                    }
                })
                .catch((error) => {
                    throw error
                });
        }
    }
}

</script>